<template>
  <div class="modal">
    <div class="header">
      <label>{{ title }}</label>
      <button class="btn close" v-on:click="onClose">Закрыть</button>
    </div>
    <div>It is modal</div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    isOpen: Boolean,
    title: String
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    }
  }
})
export default class Modal extends Vue {
}
</script>

<style lang="scss">
@import "src/assets/scss/theme";
.modal {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>