import {PageResponseModel} from '@/models/Common';
import {ClientModel} from '@/models/ClientModel';

export interface ClientStoreInterface {
  listClients: PageResponseModel<ClientModel>;
  clientById: ClientModel | null;
  // clientByCode: ClientModel | null;
}

export const ClientState: ClientStoreInterface = {
  listClients: {
    list: [],
    totalCount: 0
  },
  clientById: null,
  // clientByCode: null
}