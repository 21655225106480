<template>
  <div class="container">
    <h2>Авторизоваться</h2>
    <form class="card login-card">
      <div class="login-fields">
        <div class="email form-control">
          <label class="label">Логин</label>
          <input v-model="inputEmail"/>
        </div>
        <div class="divider"/>
        <div class="password form-control">
          <label class="label">Пароль</label>
          <input v-model="inputPassword" type="password"/>
        </div>
      </div>
      <div class="divider"/>
      <div class="action">
        <button class="btn" v-on:click="onLogin($event)">Войти</button>
      </div>
    </form>
  </div>
</template>

<script>
import {login} from '@/store/Auth/actions';

export default {
  name: "Login",
  data() {
    return {
      inputEmail: '',
      inputPassword: ''
    }
  },
  methods: {
    onLogin(event) {
      event.preventDefault();
      const {dispatch} = this.$store;
      login({
        username: this.inputEmail,
        password: this.inputPassword
      }).then(res => {
        console.log(res);
        if (res.status === 200) {
          dispatch('getCurrentUser');
          this.$router.push('/documents');
        }
      });
    }
  },
  watch: {
    inputEmail(value) {
      this.inputEmail = value;
    },
    inputPassword(value) {
      this.inputPassword = value;
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/theme";

h2 {
  color: #fff;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  .login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px !important;
    .divider {
      height: 15px;
    }
    .login-fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .label {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}
</style>