import {ClientState, ClientStoreInterface} from './state';
import {ClientActions} from './actions';
import {PageResponseModel} from '@/models/Common';
import {ClientModel} from '@/models/ClientModel';
import {StoreOptions} from 'vuex';

export const ClientModule = {
  state: () => ClientState,
  actions: ClientActions,
  mutations: {
    setListClients(state: ClientStoreInterface, data: PageResponseModel<ClientModel>) {
      console.log(data);
      state.listClients = data;
    },
    setClient(state: ClientStoreInterface, client: ClientModel) {
      state.clientById = client;
    }
  }
}
