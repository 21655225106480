import { FilterRequest } from '@/interfaces/common';
import Axios from 'axios';
import Paths from '@/paths/index';


export const getClientByCode = (code: string) => {
  return Axios.post(Paths.Client.searchByCode, { code }, {
    withCredentials: true
  });
}
export const ClientActions = {
  getListClients(context: any, filterRequest: FilterRequest) {
    Axios.post(Paths.Client.list, {
      pageRequest: filterRequest.pageRequest,
      searchString: filterRequest.searchString
    }, {
      withCredentials: true
    }).then(res => {
      context.commit('setListClients', res.data)
    });
  },

  getClientById(context: any, clientId: number) {
    Axios.get(Paths.Client.byId(clientId), {
      withCredentials: true
    }).then(res => {
      context.commit('setClient', res.data)
    });
  }
};
