import Axios from 'axios';
import Paths from '@/paths';
import {AuthRequest} from '@/interfaces/Auth';
export const login = (data: AuthRequest) => {
  return Axios.post(Paths.Auth.login, data, {
    withCredentials: true
  });
}

export const changeClientManagerPhone = (id: number, phone: string) => {
  return Axios.post(Paths.Auth.changeClientPhoneNumber(id), {
    phone
  }, {
    withCredentials: true
  });
};

export const changePresenterPhoneNumber = (id: number, phone: string) => {
  return Axios.post(Paths.Auth.changePresenterPhoneNumber(id), {
    phone
  }, {
    withCredentials: true
  });
};

export const AuthActions = {
  getCurrentUser(context: any) {
    Axios.get(Paths.Auth.currentUser, {withCredentials: true}).then(res => {
      if (res.status === 200) {
        context.commit('setCurrentUser', res.data);
        context.commit('setLoggedIn', true);
      }
    }).catch((err) => {
      context.commit('setLoggedIn', false)
    });
  },
  handleLogOut(context: any) {
    Axios.post(Paths.Auth.logout, {withCredentials: true}).then(res => {
      if (res.status === 200) {
        context.commit('setLoggedIn', false);
        window.location.href = '/login';
      }
    }).catch((err) => {
      context.commit('setLoggedIn', true)
    });
  },
  login(context: any, data: AuthRequest) {
    Axios.post(Paths.Auth.login, data, {
      withCredentials: true
    }).then(res => {
      if (res.status === 200) {
        context.commit('setLoggedIn', true);
      }
    }).catch((err) => {
      context.commit('setLoggedIn', false)
    });
  }
};
