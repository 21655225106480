import {CalculationStoreInterface, CalculationState} from './state';
import {CalculationActions} from './actions';
import {PageResponseModel} from '@/models/Common';
import {StoreOptions} from 'vuex';

export const CalculationModule = {
  state: () => CalculationState,
  actions: CalculationActions,
  mutations: {
    setDensity(state: CalculationStoreInterface, density: Array<{price: number, from: number, to: number, type: string}>) {
      state.density = density;
    },
    setPayByFreight(state: CalculationStoreInterface, payByFreight: Array<{days: number, type: string}>) {
      state.payByFreight = payByFreight;
    },
    setDeliveryByFreight(state: CalculationStoreInterface, deliveryByFreight: Array<{days: number, type: string}>) {
      state.deliveryByFreight = deliveryByFreight;
    }
  }
}
