export const Translation = {
  view: {
    label: {
      RUS: 'НАКЛАДНАЯ',
      CHN: '运单号'
    },
    total_price: {
      RUS: 'Итого к оплате',
      CHN: '总费用'
    },
    deliver_price_percentage: {
      RUS: 'Страховка Груза',
      CHN: '保险'
    },
    pay_to_check_product_to_usd: {
      RUS: 'Плата за проверку товара',
      CHN: '验货费'
    },
    total_for_delivery_to_storage: {
      RUS: 'Доставка на наш склад в Китае',
      CHN: '国内垫付运费'
    },
    item_to_usd: {
      RUS: 'Упаковка',
      CHN: '打包费'
    },
    tariff: {
      RUS: 'Тариф/кг',
      CHN: '价格'
    },
    unload_reload: {
      RUS: 'Разгрузка/Перегрузка',
      CHN: '落地费/中转费'
    },
    different_payment: {
      RUS: 'Прочие расходы',
      CHN: '其他费用'
    },
    total_tariff: {
      RUS: 'Общий тариф',
      CHN: '运费'
    },
    delivery_price: {
      RUS: 'Стоимость груза',
      CHN: '货值'
    }
  },
  clientManager: {
    RUS: 'клиент-менеджер',
    CHN: '翻译员联系方式'
  },
  moscowPresenter: {
    RUS: 'представитель в Москве',
    CHN: '办事处联系方式'
  },
  createDocument: {
    RUS: 'Создание накладной',
    CHN: '创建发票'
  },
  label: {
    RUS: 'Маркировка',
    CHN: '运单号'
  },
  code: {
    RUS: 'Код клиента',
    CHN: '客户号'
  },
  full_name: {
    RUS: 'ФИО',
    CHN: '姓名'
  },
  response_phone_number: {
    RUS: 'Номер телефона отвественного лица',
    CHN: ''
  },
  phone_number: {
    RUS: 'Номер телефон',
    CHN: '电话号'
  },
  departure_point: {
    RUS: 'Пункт отправки',
    CHN: '发货城市'
  },
  departure_date: {
    RUS: 'Дата отправки',
    CHN: '发货日期'
  },
  delivery_point: {
    RUS: 'Пункт доставки',
    CHN: '到货城市'
  },
  arrival_date: {
    RUS: 'Дата доставки',
    CHN: '预计到货日期'
  },
  passport: {
    RUS: 'Паспорт',
    CHN: '护照号'
  },
  freight_type: {
    RUS: 'Вид фрахта',
    CHN: '运输方式'
  },
  market: {
    RUS: 'Рынок',
    CHN: '到货市场'
  },
  reload_to_the_city: {
    RUS: 'Перегрузка до города',
    CHN: '中转城市'
  },
  reload_to_tk: {
    RUS: 'Перегрузка на ТК',
    CHN: '中转公司名称'
  },
  product_name: {
    RUS: 'Наименование товара',
    CHN: '货物品名'
  },
  product_type: {
    RUS: 'Тип товара',
    CHN: '货物种类'
  },
  product: {
    RUS: 'Товар',
    CHN: '货物'
  },
  calculation: {
    RUS: 'Дополнительная оплата',
    CHN: '计算'
  },
  fabrication: {
    RUS: 'Подделка',
    CHN: '是否仿牌'
  },
  count_items: {
    RUS: 'Количество',
    CHN: '小件数'
  },
  number_of_places: {
    RUS: 'К-во мест',
    CHN: '包数'
  },
  density: {
    RUS: 'Плотность',
    CHN: '密度'
  },
  tariff: {
    RUS: 'Тариф/кг ( $ )',
    CHN: '价格'
  },
  total_tariff: {
    RUS: 'Общий тариф ( $ )',
    CHN: '运费'
  },
  weight: {
    RUS: 'Вес(кг)',
    CHN: '重量kg'
  },
  volume: {
    RUS: 'Обьем(м3)',
    CHN: '体积'
  },
  pay_to_check_product: {
    RUS: 'Плата за проверку товара ( ¥ )',
    CHN: '验货费 ( ¥ )'
  },
  pay_to_check_product_to_usd: {
    RUS: 'Плата за проверку товара ( $ )',
    CHN: '验货费 ( $ )'
  },
  item_type: {
    RUS: 'Тип Упаковки',
    CHN: '包装类型'
  },
  item: {
    RUS: 'Упаковка ( ¥ )',
    CHN: '打包费 ( ¥ )'
  },
  item_to_usd: {
    RUS: 'Упаковка ( $ )',
    CHN: '打包费 ( $ )'
  },
  delivery_to_warehouse: {
    RUS: 'Доставка на наш склад в Китае ( ¥ )',
    CHN: '国内垫付运费 ( ¥ )'
  },
  delivery_price: {
    RUS: 'Стоимость груза ( $ )',
    CHN: '货值 ( $ )'
  },
  unload_reload: {
    RUS: 'Разгрузка/Перегрузка ( ₽ )',
    CHN: '落地费/中转费'
  },
  unload_reload_currency: {
    RUS: 'Разгрузка/Перегрузка курс ( ₽ )',
    CHN: '落地费/中转费卢布汇率'
  },
  delivery_price_for_kg: {
    RUS: 'Стоимость груза за (кг) ( $ )',
    CHN: '货值$'
  },
  deliver_price_percentage: {
    RUS: 'Стоимость груза в процентах %',
    CHN: '货值%'
  },
  yuan_to_usd: {
    RUS: 'Курс ¥',
    CHN: '付款¥'
  },
  total_for_delivery_to_storage: {
    RUS: 'Доставка на наш склад в Китае ( $ )',
    CHN: '国内垫付运费 ( $ )'
  },
  total_for_delivery_price: {
    RUS: 'Общая стоимость груза ( $ )',
    CHN: '总货值'
  },
  total_for_reload_unload: {
    RUS: 'Общая стоимоть разгрузки/перегрузки ( $ )',
    CHN: '落地费/中转费'
  },
  different_payment: {
    RUS: 'Прочие расходы ( $ )',
    CHN: '其他费用 ( $ )'
  },
  total_price: {
    RUS: 'Итого к оплате ( $ )',
    CHN: '总费用'
  },
  pay_by_yuan: {
    RUS: 'Курс ¥',
    CHN: '付款¥'
  },
  pay_to_date: {
    RUS: 'Оплата до',
    CHN: '付款截止日期'
  },
  description_expenses: {
    RUS: 'Описание прочих платежей',
    CHN: '其他说明'
  },
  address: {
    RUS: 'Адрес',
    CHN: '地址'
  },
  description: {
    RUS: 'Примечание',
    CHN: '注意事项'
  },
  whatsapp_phone_number: {
    RUS: 'WhatsApp для жалоб',
    CHN: '投诉whatsapp'
  },
  summary: {
    RUS: 'Заключене',
    CHN: '结论'
  },
  createDocumentButton: {
    RUS: 'Создать',
    CHN: '创建'
  },
  editDocumentButton: {
    RUS: 'Сохранить',
    CHN: '创建'
  }
};
