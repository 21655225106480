<template>
<div class="container">
  <div class="back">
    <button class="btn" v-on:click="onMoveBack($event)">Назад</button>
  </div>
  <div v-if="clientById" class="card view-client-card">
    <div class="layer">
      <div class="code item">
        <label>Код Клиента</label>
        <div>{{clientById.code}}</div>
      </div>
      <div class="passport item">
        <label>Паспорт</label>
        <div>{{clientById.passport}}</div>
      </div>
    </div>
    <hr/>
    <div class="layer">
      <div class="departurePoint item">
        <label>Пункт отправки</label>
        <div>{{clientById.departure_point}}</div>
      </div>
      <div class="deliveryPoint item">
        <label>Пункт доставки</label>
        <div>{{clientById.delivery_point}}</div>
      </div>
    </div>
    <hr/>
    <div class="layer">
      <div class="market item">
        <label>Рынок</label>
        <div>{{clientById.market}}</div>
      </div>
      <div class="reloadToTheCity item">
        <label>Перегрузка до города</label>
        <div>{{clientById.reload_to_the_city}}</div>
      </div>
    </div>
    <hr/>
    <div class="layer">
      <div class="reloadToTk item">
        <label>Перегрузка на ТК</label>
        <div>{{clientById.reload_to_tk}}</div>
      </div>
      <div class="fullName item">
        <label>ФИО</label>
        <div>{{clientById.full_name}}</div>
      </div>
    </div>
    <hr/>
    <div class="layer">
      <div class="phoneNumber item">
        <label>Телефоный номер</label>
        <div>{{clientById.phone_number}}</div>
      </div>
      <div class="freightType item">
        <label>Вид фрахта</label>
        <div>{{clientById.freight_type}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ViewClient",
  mounted() {
    console.log(this.clientId);
    const {dispatch} = this.$store;
    dispatch('getClientById', this.clientId);
  },
  methods: {
    onMoveBack(event) {
      event.preventDefault();
      this.$router.push('/clients');
    }
  },
  computed: {
    clientById() {
      const {state} = this.$store;
      return state.clientModule.clientById
    }
  },
  data() {
    const clientId = Number(this.$route.params.id);
    return {
      clientId
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/theme";
.container {
  .back {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
  }
  .view-client-card {
    width: 100%;
    height: 400px !important;
    .layer {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      .item {
        label {
          font-weight: bold;
          color: #3eaf7c;
        }
        width: 100%;
      }
    }
  }
}
</style>