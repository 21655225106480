import Vue, { createApp } from 'vue'
import { createStore } from 'vuex';
import {store} from '@/store/index';
import App from './App.vue';
import router from './router';
import moment from 'moment';

// (Vue as any).prototype.moment = moment;

const app = createApp(App).use(router);

app.use(store);

window.moment = moment;
app.mount('#app');
