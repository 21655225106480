import {UserModel} from '@/models/UserModel';

export interface AuthStoreInterface {
  userInfo: UserModel | null;
  isLoggedIn: boolean | null;
}

export const AuthState: AuthStoreInterface = {
  userInfo: null,
  isLoggedIn: null
}