import { FilterRequest } from '@/interfaces/common';
import Axios from 'axios';
import Paths from '@/paths/index';
export const createDocument = ( form: any) =>{
  return Axios.post(Paths.Document.create, form, {
    withCredentials: true
  });
}
export const editDocument = (id: number, form: any) => {
  return Axios.put(Paths.Document.byId(id), form, {
    withCredentials: true
  });
}
export const getLastDocument = () => {
  return Axios.post(Paths.Document.list, undefined,{
    withCredentials: true
  });
}
export const setCargoTrack = (id: number, key: string, custom_clearance_place: string) => {
  return Axios.post(Paths.Document.setCargoPlace(id), {
    key,
    custom_clearance_place
  }, {
    withCredentials: true
  });
}
export const DocumentActions = {
  getListDocuments(context: any, filterRequest: FilterRequest) {
    console.log(context);
    Axios.post(Paths.Document.list, {
      pageRequest: filterRequest.pageRequest,
      searchString: filterRequest.searchString
    }, {
      withCredentials: true
    }).then(res => {
      context.commit('setListDocuments', res.data);
    });
  },
  getDocumentById(context: any, id: number) {
    Axios.get(Paths.Document.byId(id), {
      withCredentials: true
    }).then(res => {
      context.commit('setDocumentById', res.data);
    });
  },
  getPhones(context: any) {
    Axios.get(Paths.Auth.getPhones, {
      withCredentials: true
    }).then(res => {
      context.commit('setPhones', res.data);
    });
  },
  getCargoTrackById(context: any, id: number) {
    Axios.get(Paths.Document.publicCargoPlace(id), {
      withCredentials: true
    }).then(res => {
      context.commit('setCargoPlace', res.data);
    });
  }
};
