import {PageResponseModel} from '@/models/Common';
import {ClientModel} from '@/models/ClientModel';

export interface CalculationStoreInterface {
  density: Array<{from: number, to: number, price: number, type: string}>;
  payByFreight: Array<{days: number, type: string}>;
  deliveryByFreight: Array<{days: number, type: string}>;
}

export const CalculationState: CalculationStoreInterface = {
  density: [],
  payByFreight: [],
  deliveryByFreight: []
}