import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import ListDocuments from '../views/ListDocuments.vue';
import ListClients from '../views/ListClients.vue';
import Calculation from '../views/Calculation.vue';
import ViewDocument from '../views/ViewDocument.vue';
import ViewClient from '../views/ViewClient.vue';
import CreateDocument from '../views/CreateDocument.vue';
import EditDocument from '../views/EditDocument.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'documents',
        name: 'List Documents',
        component: ListDocuments
      },
      {
        path: 'clients',
        name: 'List Clients',
        component: ListClients
      },
      {
        path: 'calculation',
        name: 'Calculation',
        component: Calculation
      },
      {
        path: '/login',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/documents/:id',
    name: 'View Document',
    component: ViewDocument
  },
  {
    path: '/documents/:id/edit',
    name: 'Edit Document',
    component: EditDocument
  },
  {
    path: '/documents/create',
    name: 'Create Document',
    component: CreateDocument
  },
  {
    path: '/clients/:id',
    name: 'View Client',
    component: ViewClient
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
