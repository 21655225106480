import {UserModel} from '@/models/UserModel';
import {PageResponseModel} from '@/models/Common';
import {CargoPlaceDto, DocumentModel} from '@/models/DocumentModel';

export interface DocumentStoreInterface {
  listDocuments: PageResponseModel<DocumentModel>;
  documentById: DocumentModel | null;
  cargoPlace: CargoPlaceDto | null;
  phones: {
    id: number,
    clientManager: string;
    presenter: string;
  } | null;
}

export const DocumentState: DocumentStoreInterface = {
  listDocuments: {
    list: [],
    totalCount: 0
  },
  documentById: null,
  cargoPlace: null,
  phones: null
}