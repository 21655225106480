import { InjectionKey } from 'vue';
import {createStore, Store} from 'vuex';
import {AuthModule} from '@/store/Auth';
import {DocumentModule} from '@/store/Document';
import {CalculationModule} from '@/store/Calculation';
import {ClientModule} from '@/store/Client';

export const store = createStore({
  modules: {
    authModule: AuthModule,
    clientModule: ClientModule,
    commonModule: CalculationModule,
    documentModule: DocumentModule
  }
})
