
import { Options, Vue } from 'vue-class-component';

@Options({
  mounted() {
    const {dispatch} = this.$store;
    dispatch('getCurrentUser');
  },
  data() {
    return {
      active: this.$route.path
    }
  },
  methods: {
    onMove(path: string) {
      this.$router.push(path);
      this.active = path;
    },
    onLogOut(event: any) {
      event.preventDefault();
      const {dispatch} = this.$store;
      dispatch('handleLogOut');
    }
  },
  computed: {
    isLoggedIn() {
      const {state} = this.$store;
      const { isLoggedIn } = (state as any).authModule;
      return isLoggedIn;
    },
    userInfo() {
      const {state} = this.$store;
      const {userInfo} = (state as any).authModule;
      return userInfo;
    }
  },
  watch: {
    isLoggedIn(isLogged) {
      const dashboard = this.$route.path !== '/' && this.$route.path !== '/login' ? this.$route.path : '/documents';
      console.log(dashboard);
      this.$router.push(isLogged ? dashboard : '/login');
    }
  }
})
export default class Home extends Vue {}
