export const Freight_types = {
  express: 'Экспресс',
  fly: 'Авиа',
  slowFly: 'Медленная Авиа',
  fastAuto: 'Быстрый авто',
  fastWearAuto: 'Быстрый авто (Одежда)',
  fastWhiteAuto: 'Быстрый авто (по белой)',
  slowAuto: 'Медленный Авто',
  slowWearAuto: 'Медленный авто (Одежда)',
  train: 'Поезд',
  trainWithWear: 'Поезд (Одежда)',
  oneWayTrain: 'Поезд (Один пояс один путь)'
}

export const ItemTypes: {[key: string]: string} = {
  from_tree: 'Дерева',
  general: 'Обычная упаковка',
  from_skin: 'Упаковка из пленки',
  pallet: 'Палет',
  lathing: 'Обрешетка'
}