const configuration = process.env.BACKEND_HOST || `https://api.alial-express.com`;

const api = (path: string) => `${configuration}/api/${path}`;
const Paths = {
  Auth: {
    currentUser: api('auth/currentUser'),
    logout: api('auth/logout'),
    login: api('auth/login'),
    changeClientPhoneNumber: (id: number) => api(`admin/changeClientPhoneNumber/${id}`),
    changePresenterPhoneNumber: (id: number) => api(`admin/changePresenterPhoneNumber/${id}`),
    getPhones: api(`admin/getPhones`),
  },
  calculation: {
    density: (type: string) => api(`calculation/density/${type}`),
    payByFreight: (type: string) => api(`calculation/payByFreight/${type}`),
    deliveryByFreight: (type: string) => api(`calculation/deliveryByFreight/${type}`)
  },
  Client: {
    byId: (id: number) => api(`clients/${id}`),
    searchByCode: api(`clients/byCode`),
    list: api(`clients`)
  },
  Document: {
    byId: (id: number) => api(`documents/${id}`),
    list: api(`documents`),
    getLastDocument: api(`documents/getLastDocument`),
    create: api(`documents/create`),
    setCargoPlace: (id: number) => api(`documents/${id}/setTrack`),
    publicCargoPlace: (id: number) => api(`public/getCargoTrackById/${id}`)
  }
}

export default Paths;
