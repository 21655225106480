<template>
  <div class="container">

  <form id="app" @submit="submitForm" class="card">
    <div class="left" style="display: flex; justify-content: flex-start; margin-bottom: 10px;">
      <button class="btn" v-on:click="onMoveBack($event)">Назад</button>
    </div>
    <div class="header-title">
      <span class="createDocument">{{ translation.createDocument[selectLanguage] }}</span>
      <div class="form-control">
        <select v-model="selectLanguage">
          <option value="RUS">Русский</option>
          <option value="CHN">中国人</option>
        </select>
      </div>
    </div>
    <div class="header">
      <div class="form-control marker">
        <label for="documentForm.label">
          {{translation.label[selectLanguage]}}
        </label>
        <input
            type="text"
            id="documentForm.label"
            name="documentForm.label"
            v-model="documentForm.label"
        />
      </div>
      <div class="form-control code">
        <label for="documentForm.code">
          {{translation.code[selectLanguage]}}
        </label>
        <input
            type="text"
            id="documentForm.code"
            name="documentForm.code"
            v-model="documentForm.code"
            v-on:input="onSearchClientByCode($event)"
        />
      </div>
    </div>
    <hr/>
    <div class="receiver">
      <div class="left-side">
        <div class="form-control">
          <label for="documentForm.full_name">
            {{translation.full_name[selectLanguage]}}
          </label>
          <input
              type="text"
              id="documentForm.full_name"
              name="documentForm.full_name"
              v-model="documentForm.full_name"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.response_phone_number">
            {{translation.response_phone_number[selectLanguage]}}
          </label>
          <input
              type="text"
              id="documentForm.response_phone_number"
              name="documentForm.response_phone_number"
              v-model="documentForm.response_phone_number"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.phone_number">
            {{translation.phone_number[selectLanguage]}}
          </label>
          <input
              type="text"
              id="documentForm.phone_number"
              name="documentForm.phone_number"
              v-model="documentForm.phone_number"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.passport">
            {{translation.passport[selectLanguage]}}
          </label>
          <input
              type="text"
              id="documentForm.passport"
              name="documentForm.passport"
              v-model="documentForm.passport"
          />
        </div>
        <div class="form-control">
          <label>
            {{translation.freight_type[selectLanguage]}}
          </label>
          <select v-model="documentForm.freight_type">
            <option :value="freight_types.express">{{ freight_types.express }}</option>
            <option :value="freight_types.fly">{{ freight_types.fly }}</option>
            <option :value="freight_types.slowFly">{{ freight_types.slowFly }}</option>
            <option :value="freight_types.fastAuto">{{ freight_types.fastAuto }}</option>
            <option :value="freight_types.fastWhiteAuto">{{ freight_types.fastWhiteAuto }}</option>
            <option :value="freight_types.slowAuto">{{ freight_types.slowAuto }}</option>
            <option :value="freight_types.train">{{ freight_types.train }}</option>
            <option :value="freight_types.oneWayTrain">{{ freight_types.oneWayTrain }}</option>
            <option :value="freight_types.fastWearAuto">{{ freight_types.fastWearAuto }}</option>
            <option :value="freight_types.slowWearAuto">{{ freight_types.slowWearAuto }}</option>
            <option :value="freight_types.trainWithWear">{{ freight_types.trainWithWear }}</option>
          </select>
        </div>
      </div>
      <div class="right-side">
        <div class="departure_point_container">
          <div class="form-control">
            <label for="documentForm.departure_point">
              {{translation.departure_point[selectLanguage]}}
            </label>
            <select v-model="documentForm.departure_point_type">
              <option :value="departurePoints.Beijing">{{departurePoints.Beijing}}</option>
              <option :value="departurePoints.Guanzhou">{{departurePoints.Guanzhou}}</option>
              <option :value="departurePoints.IU">{{departurePoints.IU}}</option>
              <option :value="departurePoints.Urumchi">{{departurePoints.Urumchi}}</option>
              <option :value="departurePoints.Other">{{departurePoints.Other}}</option>
            </select>
            <div style="height: 5px;"/>
            <input
                v-if="documentForm.departure_point_type === departurePoints.Other"
                type="text"
                placeholder="Введите другой город"
                id="documentForm.departure_point"
                name="documentForm.departure_point"
                v-model="documentForm.departure_point"
            />
          </div>
        </div>
        <div class="form-control">
          <label for="documentForm.departure_date">
            {{translation.departure_date[selectLanguage]}}
          </label>
          <input
              type="date"
              id="documentForm.departure_date"
              name="documentForm.departure_date"
              v-model="documentForm.departure_date"
              v-on:change="onSelectDepartureDate($event)"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.delivery_point">
            {{translation.delivery_point[selectLanguage]}}
          </label>
          <select v-model="documentForm.delivery_point_type">
            <option :value="deliveryPoints.Moscow">{{deliveryPoints.Moscow}}</option>
            <option :value="deliveryPoints.Other">{{deliveryPoints.Other}}</option>
          </select>
          <div style="height: 5px;"/>
          <input
              v-if="documentForm.delivery_point_type === departurePoints.Other"
              type="text"
              placeholder="Введите другой город"
              id="documentForm.delivery_point"
              name="documentForm.delivery_point"
              v-model="documentForm.delivery_point"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.arrival_date">
            {{translation.arrival_date[selectLanguage]}}
          </label>
          <input
              type="date"
              id="documentForm.arrival_date"
              name="documentForm.arrival_date"
              v-model="documentForm.arrival_date"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.market">
            {{translation.market[selectLanguage]}}
          </label>
          <input type="text" placeholder="Рынок" id="documentForm.market" name="documentForm.market" v-model="documentForm.market"/>
        </div>
        <div class="form-control">
          <label for="documentForm.reload_to_tk">
            {{translation.reload_to_tk[selectLanguage]}}
          </label>
          <select v-model="documentForm.reload_to_tk_type">
            <option :value="tk_places.expedition">{{tk_places.expedition}}</option>
            <option :value="tk_places.baikalService">{{tk_places.baikalService}}</option>
            <option :value="tk_places.dealLine">{{tk_places.dealLine}}</option>
            <option :value="tk_places.energy">{{tk_places.energy}}</option>
            <option :value="tk_places.sdek">{{tk_places.sdek}}</option>
            <option :value="tk_places.whale">{{tk_places.whale}}</option>
            <option :value="tk_places.Other">{{tk_places.Other}}</option>
          </select>
          <div style="height: 5px;"/>
          <input
              v-if="documentForm.reload_to_tk_type === tk_places.Other"
              type="text"
              placeholder="Введите другой ТК"
              id="documentForm.reload_to_tk"
              name="documentForm.reload_to_tk"
              v-model="documentForm.reload_to_tk"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.reload_to_the_city">
            {{translation.reload_to_the_city[selectLanguage]}}
          </label>
          <input
              type="text"
              id="documentForm.reload_to_the_city"
              name="documentForm.reload_to_the_city"
              v-model="documentForm.reload_to_the_city"
          />
        </div>
      </div>
    </div>
    <div class="product">
      <div class="first-row">
        <div class="left-side">
          <div class="form-control">
            <label for="documentForm.product_name">
              {{translation.product_name[selectLanguage]}}
            </label>
            <input type="text" id="documentForm.product_name" name="documentForm.product_name" v-model="documentForm.product_name"/>
          </div>
          <div class="form-control">
            <label for="documentForm.product_type">
              {{translation.product_type[selectLanguage]}}
            </label>
            <input type="text" id="documentForm.product_type" name="documentForm.product_type" v-model="documentForm.product_type"/>
          </div>
          <div class="form-control">
            <label for="documentForm.fabrication">
              {{translation.fabrication[selectLanguage]}}
            </label>
            <input type="text"
                   id="documentForm.fabrication" name="documentForm.fabrication" v-model="documentForm.fabrication"/>
          </div>
          <div class="form-control">
            <label for="documentForm.number_of_places">
              {{translation.number_of_places[selectLanguage]}}
            </label>
            <input type="number"
                   step="1"
                   id="documentForm.number_of_places" name="documentForm.number_of_places" v-model="documentForm.number_of_places"/>
          </div>
        </div>
        <div class="right-side">
          <div class="form-control">
            <label for="documentForm.count_items">
              {{translation.count_items[selectLanguage]}}
            </label>
            <input type="number"
                   step="1"
                   id="documentForm.count_items" name="documentForm.count_items" v-model="documentForm.count_items"/>
          </div>
          <div class="form-control">
            <label for="documentForm.weight">
              {{translation.weight[selectLanguage]}}
            </label>
            <input type="number"
                   step="0.01"
                   id="documentForm.weight" name="documentForm.weight" v-model="documentForm.weight" v-on:input="onChangeWeight($event)"/>
          </div>
          <div class="form-control">
            <label for="documentForm.volume">
              {{translation.volume[selectLanguage]}}

            </label>
            <input type="number"
                   step="0.01"
                   id="documentForm.volume" name="documentForm.volume" v-model="documentForm.volume" v-on:input="onChangeVolume($event)"/>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="form-control">
          <label for="documentForm.density">
            {{translation.density[selectLanguage]}}
          </label>
          <input disabled
                 step="0.01"
                 type="number"
                 id="documentForm.density"
                 name="documentForm.density"
                 v-model="documentForm.density"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.tariff">
            {{translation.tariff[selectLanguage]}}
          </label>
          <input
              type="number"
              step="0.01"
              id="documentForm.tariff"
              name="documentForm.tariff"
              v-model="documentForm.tariff"
              v-on:input="onChangeTariff($event)"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.total_tariff">
            {{translation.total_tariff[selectLanguage]}}
          </label>
          <input disabled
                 step="0.01"
                 type="number" id="documentForm.total_tariff" name="documentForm.total_tariff" v-model="documentForm.total_tariff"/>
        </div>
      </div>
    </div>
    <div class="calculation">
      <div class="item-container">
       <div class="editable">
         <div class="input-fields">
             <div class="form-control">
               <label for="documentForm.pay_to_check_product">
                 {{translation.pay_to_check_product[selectLanguage]}}
               </label>
               <input
                   type="number"
                   step="0.01"
                   id="documentForm.pay_to_check_product"
                   name="documentForm.pay_to_check_product"
                   v-model="documentForm.pay_to_check_product"
                   v-on:input="onChangeCheckProduct()"
               />
             </div>
             <div class="form-control">
               <label for="documentForm.pay_to_check_product_to_usd">
                 {{translation.pay_to_check_product_to_usd[selectLanguage]}}
               </label>
               <input
                   disabled
                   type="number"
                   step="0.01"
                   id="documentForm.pay_to_check_product_to_usd"
                   name="documentForm.pay_to_check_product_to_usd"
                   v-model="documentForm.pay_to_check_product_to_usd"
               />
             </div>
           <div class="form-control">
             <label>
               {{translation.item_type[selectLanguage]}}
             </label>
             <select v-model="documentForm.item_type">
               <option value="from_tree">Дерева</option>
               <option value="general">Обычная упаковка</option>
               <option value="pallet">Палет</option>
               <option value="lathing">Обрешетка</option>
               <option value="from_skin">Упаковка из пленки</option>
             </select>
           </div>
           <div class="form-control">
             <label for="documentForm.item">
               {{translation.item[selectLanguage]}}
             </label>
             <input
                 type="number"
                 step="0.01"
                 id="documentForm.item"
                 name="documentForm.item"
                 v-model="documentForm.item"
                 v-on:input="onChangeItem()"
             />
           </div>
           <div class="form-control">
             <label for="documentForm.item_to_usd">
               {{translation.item_to_usd[selectLanguage]}}
             </label>
             <input
                 disabled
                 id="documentForm.item_to_usd"
                 name="documentForm.item_to_usd"
                 v-model="documentForm.item_to_usd"
             />
           </div>
         </div>
         <div class="currency">
           <div class="form-control">
             <label for="documentForm.delivery_to_warehouse">
               {{translation.delivery_to_warehouse[selectLanguage]}}
             </label>
             <input
                 type="number"
                 step="0.01"
                 id="documentForm.delivery_to_warehouse"
                 name="documentForm.delivery_to_warehouse"
                 v-model="documentForm.delivery_to_warehouse"
                 v-on:input="onChangeWareHouse()"
             />
           </div>
           <div class="form-control">
             <label for="documentForm.total_for_delivery_to_storage">
               {{translation.total_for_delivery_to_storage[selectLanguage]}}
             </label>
             <input
                 disabled
                 type="number"
                 step="0.01"
                 id="documentForm.total_for_delivery_to_storage"
                 name="documentForm.total_for_delivery_to_storage"
                 v-model="documentForm.total_for_delivery_to_storage"
             />
           </div>
           <div class="form-control">
             <label for="documentForm.yuan_to_usd">
               {{translation.yuan_to_usd[selectLanguage]}}
             </label>
             <input
                 type="number"
                 step="0.01"
                 id="documentForm.yuan_to_usd"
                 name="documentForm.yuan_to_usd"
                 v-model="documentForm.yuan_to_usd"
                 v-on:input="onChangeYuanToUsd()"
             />
           </div>
         </div>
       </div>
      </div>
      <hr/>
      <div class="deliver-container">
        <div class="form-control">
          <label for="documentForm.delivery_price">
            {{translation.delivery_price[selectLanguage]}}
          </label>
          <input
              type="number"
              step="0.01"
              id="documentForm.delivery_price"
              name="documentForm.delivery_price"
              v-model="documentForm.delivery_price"
              v-on:input="calculateTotalDeliveryPrice()"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.delivery_price_for_kg">
            {{translation.delivery_price_for_kg[selectLanguage]}}
          </label>
          <input
              disabled
              type="number"
              step="0.01"
              id="documentForm.delivery_price_for_kg"
              name="documentForm.delivery_price_for_kg"
              v-model="documentForm.delivery_price_for_kg"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.deliver_price_percentage">
            {{translation.deliver_price_percentage[selectLanguage]}}
          </label>
          <input
              type="number"
              step="0.01"
              id="documentForm.deliver_price_percentage"
              name="documentForm.deliver_price_percentage"
              v-model="documentForm.deliver_price_percentage"
              v-on:input="calculateTotalDeliveryPrice()"
          />
        </div>
      </div>
      <hr/>
      <div class="form-control">
        <label for="documentForm.total_for_delivery_price">
          {{translation.total_for_delivery_price[selectLanguage]}}
        </label>
        <input
            disabled
            type="number"
            step="0.01"
            id="documentForm.total_for_delivery_price"
            name="documentForm.total_for_delivery_price"
            v-model="documentForm.total_for_delivery_price"
        />
      </div>
      <hr/>
      <div class="unload-reload-container">
        <div class="form-control">
          <label for="documentForm.unload_reload">
            {{translation.unload_reload[selectLanguage]}}
          </label>
          <input
              type="number"
              step="0.01"
              id="documentForm.unload_reload"
              name="documentForm.unload_reload"
              v-model="documentForm.unload_reload"
              v-on:input="calculateUnloadReload()"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.unload_reload_currency">
            {{translation.unload_reload_currency[selectLanguage]}}
          </label>
          <input
              type="number"
              step="0.01"
              id="documentForm.unload_reload_currency"
              name="documentForm.unload_reload_currency"
              v-model="documentForm.unload_reload_currency"
              v-on:input="calculateUnloadReload()"
          />
        </div>
        <div class="form-control">
          <label for="documentForm.total_for_reload_unload">
            {{translation.total_for_reload_unload[selectLanguage]}}
          </label>
          <input
              disabled
              type="number"
              step="0.01"
              id="documentForm.total_for_reload_unload"
              name="documentForm.total_for_reload_unload"
              v-model="documentForm.total_for_reload_unload"
          />
        </div>
      </div>
    </div>
    <hr/>
    <div class="expenses">
      <div class="form-control">
        <label for="documentForm.different_payment">
          {{translation.different_payment[selectLanguage]}}
        </label>
        <input
            step="0.01"
            type="number" id="documentForm.different_payment" name="documentForm.different_payment" v-model="documentForm.different_payment"/>
      </div>
      <div class="form-control">
        <label for="documentForm.description_expenses">
          {{translation.description_expenses[selectLanguage]}}
        </label>
        <input type="text" id="documentForm.description_expenses" name="documentForm.description_expenses" v-model="documentForm.description_expenses"/>
      </div>
    </div>
    <hr/>
    <div class="payment-info">
      <div class="form-control">
        <label for="documentForm.pay_to_date">
          {{translation.pay_to_date[selectLanguage]}}
        </label>
        <input type="date" id="documentForm.pay_to_date" name="documentForm.pay_to_date" v-model="documentForm.pay_to_date"/>
      </div>
      <div class="form-control">
        <label for="documentForm.pay_by_yuan">
          {{translation.pay_by_yuan[selectLanguage]}}
        </label>
        <input
            step="0.01"
            type="number" id="documentForm.pay_by_yuan" name="documentForm.pay_by_yuan" v-model="documentForm.pay_by_yuan"/>
      </div>
    </div>
    <hr/>
    <div class="add-info">
      <div class="form-control">
        <label for="documentForm.whatsapp_phone_number">
          {{translation.whatsapp_phone_number[selectLanguage]}}
        </label>
        <input type="text" id="documentForm.whatsapp_phone_number" name="documentForm.whatsapp_phone_number" v-model="documentForm.whatsapp_phone_number"/>
      </div>
      <div class="form-control">
        <label for="documentForm.summary">
          {{translation.summary[selectLanguage]}}
        </label>
        <input type="text" id="documentForm.summary" name="documentForm.summary" v-model="documentForm.summary"/>
      </div>
    </div>
    <hr/>
    <div class="description">
      <div class="form-control">
        <label>
          {{translation.description[selectLanguage]}}
        </label>
        <textarea name="documentForm.description" v-model="documentForm.description"/>
      </div>
    </div>
    <div class="total-price">
      <div class="form-control">
        <label for="documentForm.total_price">
          {{translation.total_price[selectLanguage]}}
        </label>
        <input
            step="0.01"
            disabled type="number" id="documentForm.total_price"  name="documentForm.total_price" :value="totalPrice"/>
      </div>
    </div>
    <div>
      <button class="btn" type="submit">{{translation.createDocumentButton[selectLanguage]}}</button>
    </div>
  </form>
  </div>
</template>
<script>

import { Options, Vue } from 'vue-class-component';
import Pagination from '@/components/Pagination.vue';
import {getClientByCode} from '@/store/Client/actions';
import {createDocument, getLastDocument} from '@/store/Document/actions';
import {Translation} from '@/common/translation';
import moment from 'moment';
import {Freight_types} from "@/common/freight_types";

@Options({
  name: "CreateDocument",
  components: {
    Pagination
  },
  mounted() {
    const {dispatch} = this.$store;
    dispatch('getDensity');
    dispatch('getPayByFreight');
    dispatch('getDeliveryByFreight');
    getLastDocument().then(res => {
      const lastDocument = res.data.list[0];
      this.documentForm.yuan_to_usd = lastDocument.yuan_to_usd;
      this.documentForm.pay_by_yuan = lastDocument.pay_by_yuan;
      this.documentForm.whatsapp_phone_number = lastDocument.whatsapp_phone_number;
      this.documentForm.description = lastDocument.description;
      this.documentForm.unload_reload_currency = lastDocument.unload_reload_currency;
    });
  },
  data() {
    return {
      freight_types: Freight_types,
      translation: Translation,
      departurePoints: {
        Beijing: 'Пекин',
        Urumchi: 'Урумчи',
        IU: 'Иу',
        Guanzhou: 'Гуанчжоу',
        Other: 'Другой город'
      },
      deliveryPoints: {
        Moscow: 'Москва',
        Other: 'Другой город'
      },
      tk_places: {
        expedition: 'Желдор Экспидиция',
        baikalService: 'Байкал Сервис',
        dealLine: 'Деловые Линии',
        energy: 'Энергия',
        sdek: 'СДЭК',
        whale: 'Кит',
        Other: 'Другой город'
      },
      selectLanguage: 'RUS',
      documentForm: {
        label: '',
        code: '',
        delivery_point_type: '',
        delivery_point: '',
        response_phone_number: '',
        departure_point_type: '',
        departure_point: '',
        market: '',
        reload_to_the_city: '',
        reload_to_tk_type: '',
        reload_to_tk: '',
        full_name: '',
        phone_number: '',
        passport: '',
        freight_type: '',
        departure_date: '',
        arrival_date: '',
        product_name: '',
        product_type: '',
        fabrication: '',
        item_type: '',
        number_of_places: 0,
        count_items: 0,
        weight: 0,
        volume: 0,
        density: 0,
        tariff: 0,
        total_tariff: 0,
        pay_to_check_product: 0,
        pay_to_check_product_to_usd: 0,
        item: 0,
        item_to_usd: 0,
        delivery_to_warehouse: 0,
        delivery_price: 0,
        unload_reload: 0,
        unload_reload_currency: 0,
        delivery_price_for_kg: 0,
        deliver_price_percentage: 0,
        yuan_to_usd: 0,
        total_for_delivery_to_storage: 0,
        total_for_delivery_price: 0,
        total_for_reload_unload: 0,
        different_payment: 0,
        total_price: 0,
        pay_by_yuan: 0,
        pay_to_date: '',
        description_expenses: '',
        address: '',
        description: '',
        whatsapp_phone_number: '',
        summary: 'Спасибо за сотрудничество !'
      }
    }
  },
  methods: {
    onChangeWeight(event) {
      const {value} = event.target;
      this.documentForm.density = this.documentForm.volume ? Math.round(value / this.documentForm.volume) : this.documentForm.density;
      const findDensity = this.density.find((item) => item.freight_type === this.documentForm.freight_type && item.from <= this.documentForm.density && item.to >= this.documentForm.density);
      if (findDensity) {
        this.documentForm.tariff = findDensity.price;
        this.documentForm.total_tariff = findDensity.type === 'KG' ?
            this.documentForm.weight * this.documentForm.tariff :
            this.documentForm.volume * this.documentForm.tariff;
      }
      this.documentForm.weight = value;
    },
    onChangeVolume(event) {
      const {value} = event.target;
      this.documentForm.density = this.documentForm.weight ? Math.round(this.documentForm.weight / value) : this.documentForm.density;
      const findDensity = this.density.find((item) => item.freight_type === this.documentForm.freight_type && item.from <= this.documentForm.density && item.to >= this.documentForm.density);
      if (findDensity) {
        this.documentForm.tariff = findDensity.price;
        this.documentForm.total_tariff = findDensity.type === 'KG' ?
            this.documentForm.weight * this.documentForm.tariff :
            this.documentForm.volume * this.documentForm.tariff;
      }
      this.documentForm.volume = value;
    },
    onChangeTariff(event) {
      this.documentForm.tariff = event.target.value;
      const findDensity = this.density.find((item) => item.freight_type === this.documentForm.freight_type && item.from <= this.documentForm.density && item.to >= this.documentForm.density);
      if (findDensity) {
        this.documentForm.total_tariff = findDensity.type === 'KG' ?
            this.documentForm.weight * this.documentForm.tariff :
            this.documentForm.volume * this.documentForm.tariff;
      }
    },
    onChangeCheckProduct() {
      const {pay_to_check_product, yuan_to_usd} = this.documentForm;
      this.documentForm.pay_to_check_product_to_usd =  Number((pay_to_check_product / yuan_to_usd).toFixed(2));
    },
    onChangeItem() {
      const {item, yuan_to_usd} = this.documentForm;
      this.documentForm.item_to_usd =  Number((item / yuan_to_usd).toFixed(2));
    },
    onChangeWareHouse() {
      const {delivery_to_warehouse, yuan_to_usd} = this.documentForm;
      this.documentForm.total_for_delivery_to_storage =  Number((delivery_to_warehouse / yuan_to_usd).toFixed(2));
    },
    onChangeYuanToUsd() {
      const {item, pay_to_check_product, delivery_to_warehouse, yuan_to_usd} = this.documentForm;
      this.documentForm.item_to_usd = Number((item / yuan_to_usd).toFixed(2));
      this.documentForm.pay_to_check_product_to_usd = Number((pay_to_check_product / yuan_to_usd).toFixed(2));
      this.documentForm.total_for_delivery_to_storage =  Number((delivery_to_warehouse / yuan_to_usd).toFixed(2));
    },
    calculateTotalDeliveryPrice() {
      const {delivery_price, deliver_price_percentage, weight} = this.documentForm;
      this.documentForm.delivery_price_for_kg = Number((delivery_price / weight).toFixed(2));
      this.documentForm.total_for_delivery_price = Number((delivery_price / (100 / deliver_price_percentage)).toFixed(2));
    },
    calculateUnloadReload() {
      const {unload_reload, unload_reload_currency, number_of_places} = this.documentForm;
      this.documentForm.total_for_reload_unload = unload_reload_currency > 0 ? Number(((unload_reload * number_of_places) / unload_reload_currency).toFixed(2)) : 0;
    },
    onSelectDepartureDate(event) {
      const {state} = this.$store;
      const foundDeliveryByFreight = state.commonModule.deliveryByFreight.find((item) => item.freight_type === this.documentForm.freight_type);
      const foundPayByFreight = state.commonModule.payByFreight.find((item) => item.freight_type === this.documentForm.freight_type);
      if (foundDeliveryByFreight) {
        this.documentForm.arrival_date = moment(event.target.value).add(foundDeliveryByFreight.days, 'days').format('yyyy-MM-DD')
      }
      if (foundPayByFreight) {
        this.documentForm.pay_to_date = moment(event.target.value).add(foundPayByFreight.days, 'days').format('yyyy-MM-DD')
      }
    },
    onSearchClientByCode(event) {
      const {value} = event.target;
      getClientByCode(value).then(res => {
        const client = res.data;
        const arrDeparturePoints = Object.keys(this.departurePoints).map((key) => this.departurePoints[key]);
        const arrDeliveryPoints = Object.keys(this.deliveryPoints).map((key) => this.deliveryPoints[key]);
        const arrTkPlaces = Object.keys(this.tk_places).map((key) => this.tk_places[key]);
        if (client) {
          this.documentForm = {
            ...this.documentForm,
            code: client.code,
            delivery_point: client.delivery_point,
            delivery_point_type: arrDeliveryPoints.includes(client.delivery_point) ? client.delivery_point : this.departurePoints.Other,
            departure_point_type: arrDeparturePoints.includes(client.departure_point) ? client.departure_point : this.departurePoints.Other,
            departure_point: client.departure_point,
            market: client.market,
            reload_to_the_city: client.reload_to_the_city,
            reload_to_tk_type: arrTkPlaces.includes(client.reload_to_tk) ? client.reload_to_tk : this.tk_places.Other,
            reload_to_tk: client.reload_to_tk,
            response_phone_number: client.response_phone_number,
            full_name: client.full_name,
            phone_number: client.phone_number,
            passport: client.passport,
            freight_type: client.freight_type
          }
        }
      })
    },
    onMoveBack() {
      this.$router.push(`/documents`);
    },
    submitForm(event) {
      event.preventDefault();
      const {departure_point_type, departure_point, delivery_point_type, delivery_point, reload_to_tk_type, reload_to_tk} = this.documentForm;
      this.documentForm.departure_point = departure_point_type !== this.departurePoints.Other ? departure_point_type : departure_point;
      this.documentForm.delivery_point = delivery_point_type !== this.deliveryPoints.Other ? delivery_point_type : delivery_point;
      this.documentForm.reload_to_tk = reload_to_tk_type !== this.tk_places.Other ? reload_to_tk_type : reload_to_tk;
      console.log(this.documentForm);
      createDocument(this.documentForm).then(res => {
        console.log('created document: ', res);
        this.$router.push(`/documents/${res.data.id}`);
      })
    }
  },
  computed: {
    totalPrice() {
      const {total_for_reload_unload, total_for_delivery_price, total_tariff, total_for_delivery_to_storage, pay_to_check_product_to_usd, item_to_usd, different_payment} = this.documentForm;
      this.documentForm.total_price = Math.round((
          total_for_delivery_price +
          total_tariff +
          total_for_delivery_to_storage +
          pay_to_check_product_to_usd +
          item_to_usd +
          total_for_reload_unload
      ) - different_payment).toFixed(0);
      return this.documentForm.total_price;
    },
    clientByCode() {
      const {state} = this.$store;
      return state.clientModule.clientByCode;
    },
    density() {
      const {state} = this.$store;
      return state.commonModule.density
    },
    payByFreight() {
      const {state} = this.$store;
      return state.commonModule.payByFreight
    },
    deliveryByFreight() {
      const {state} = this.$store;
      return state.commonModule.deliveryByFreight
    }
  }
})
export default class CreateDocument extends Vue {}
</script>

<style lang="scss">
@import "src/assets/scss/theme";
.container {
  .card {
    height: 100vh;
    overflow-y: auto;
    .header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: start;
      margin-bottom: 10px;
    }
    label {
      font-size: 13px;
      font-weight: 600;
    }
    .form-control {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 300px;
    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      .form-control {
        width: 100%;
      }
      .marker {
        margin-right: 5px;
      }
      .code {
        margin-left: 5px;
      }
    }
    .receiver {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .left-side {
      }
      .right-side {
      }
    }
    .product {
      border-top: 1px solid $divier;
      padding-top: 10px;
      .first-row {
        display: flex;
        justify-content: space-between;
      }
      .info {
        display: flex;
        justify-content: space-between;
      }
    }
    .calculation {
      border-top: 1px solid $divier;
      padding-top: 10px;
      .item-container {
        .editable {
          display: flex;
          justify-content: space-between;
        }
        .item-total {
          display: flex;
          justify-content: space-between;
        }
      }
      .deliver-container {
        display: flex;
        justify-content: space-between;
      }
      .unload-reload-container {
        display: flex;
        justify-content: space-between;
      }
    }
    .expenses {
      display: flex;
      justify-content: space-between;
    }
    .payment-info {
      display: flex;
      justify-content: space-between;
    }
    .add-info {
      display: flex;
      justify-content: space-between;
    }
    .description {
      textarea {
        width: 300px;
        height: 100px;
      }
    }
    .total-price {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.createDocument {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>