<template>
<div v-if="documentById" class="container">
  <div class="actions">
    <div class="left">
      <button class="btn" v-on:click="onMoveBack($event)">Назад</button>
    </div>
    <div class="center">
      <div class="form-control">
        <select v-model="selectLanguage">
          <option value="RUS">Русский</option>
          <option value="CHN">中国人</option>
        </select>
      </div>
    </div>
    <div class="right">
      <button class="btn" v-on:click="onEditDocument($event)">Редактировать</button>
      <button class="btn primary" v-on:click="onDownloadImage($event)">Скачать форму</button>
    </div>
  </div>
  <div class="both-cards" style="display: flex;">
    <div id="card-id" class="outer-layer-card">
      <div class="card custom-card" style="height: auto">
        <div class="header">
          <div class="left-side">
            <div class="logo" style="padding-bottom: 8px;">
              <img :src="logo">
            </div>
            <div class="document-label">
              <span>{{ translation.view.label[selectLanguage] }}: </span>
              <span>{{documentById.label}}</span>
            </div>
          </div>
          <div class="right-side">
            <span class="top-info company-title">Alial Delivery Company</span>
            <span class="top-info">
            <a href="http://info.alial-express.com" v-bind:target="'_blank'" style="color: #2c3e50">
            info.alial-express.com
            </a>
          </span>
            <span class="top-info">
            {{documentById.client.response_phone_number}}
          </span>
            <span class="top-info">
            <span v-if="getPhonesNumber" contenteditable="true" v-on:blur="onBlurPresenter($event)">{{getPhonesNumber.presenter}}</span>
            ({{ translation.moscowPresenter[selectLanguage] }})
          </span>
          </div>
        </div>
        <hr/>
        <div class="receiver-label">1. Получатель</div>
        <div class="receiver" style="align-items: flex-end;">
          <div class="left-side">
            <div class="full-name">
              <span class="field-label">{{ translation.full_name[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.full_name}}</span>
            </div>
            <div class="full-name">
              <span class="field-label">{{ translation.code[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.code}}</span>
            </div>
            <div class="full-name">
              <span class="field-label">{{ translation.phone_number[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.phone_number}}</span>
            </div>
            <div class="full-name">
              <span class="field-label">{{ translation.passport[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.passport}}</span>
            </div>
            <div class="full-name">
              <span class="field-label">{{ translation.freight_type[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.freight_type}}</span>
            </div>
          </div>
          <div class="right-side">
            <div class="delivery-point">
              <span class="field-label">{{ translation.departure_point[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.departure_point}}</span>
            </div>
            <div class="delivery-point">
              <span class="field-label">{{ translation.departure_date[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.departure_date}}</span>
            </div>
            <div class="delivery-point">
              <span class="field-label">{{ translation.delivery_point[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.delivery_point}}</span>
            </div>
            <div class="delivery-point">
              <span class="field-label">{{ translation.market[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.market}}</span>
            </div>
            <div class="delivery-point">
              <span class="field-label">{{ translation.arrival_date[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.arrival_date}}</span>
            </div>
            <div class="delivery-point">
              <span class="field-label">{{ translation.reload_to_tk[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.reload_to_tk}}</span>
            </div>
            <div class="delivery-point">
              <span class="field-label">{{ translation.reload_to_the_city[selectLanguage] }}:</span>
              <span class="field-value">{{documentById.client.reload_to_the_city}}</span>
            </div>
          </div>
        </div>
        <hr/>
        <div class="product-label">2. {{translation.product[selectLanguage]}}</div>
        <div class="product">
          <div class="header-row">
            <div class="product-name-header-cell product-name">{{ translation.product_name[selectLanguage] }}</div>
            <div class="product-type-header-cell product-type">{{ translation.product_type[selectLanguage] }}</div>
            <div class="header-cell fabrication">{{ translation.fabrication[selectLanguage] }}</div>
            <div class="header-cell number-of-places">{{ translation.number_of_places[selectLanguage] }}</div>
            <div class="header-cell count-items">{{ translation.count_items[selectLanguage] }}</div>
            <div class="header-cell weight">{{ translation.weight[selectLanguage] }}</div>
            <div class="header-cell volume">{{ translation.volume[selectLanguage] }}</div>
            <div class="header-cell density">{{ translation.density[selectLanguage] }}</div>
            <div class="header-cell tariff">{{ translation.view.tariff[selectLanguage] }}</div>
            <div class="header-cell total-tariff">{{ translation.view.total_tariff[selectLanguage] }}</div>
          </div>
          <div class="body-row">
            <div class="product-name-cell field-value">{{documentById.product_name}}</div>
            <div class="product-type-cell field-value">{{documentById.product_type }}</div>
            <div class="body-cell field-value">{{documentById.fabrication }}</div>
            <div class="body-cell field-value">{{documentById.number_of_places }}</div>
            <div class="body-cell field-value">{{documentById.count_items }}</div>
            <div class="body-cell field-value">{{documentById.weight }}</div>
            <div class="body-cell field-value">{{documentById.volume }}</div>
            <div class="body-cell field-value">{{documentById.density }}</div>
            <div class="body-cell field-value">${{documentById.tariff.toFixed(2)}}</div>
            <div class="body-cell field-value">${{documentById.total_tariff.toFixed(2)}}</div>
          </div>
        </div>
        <hr/>
        <div class="product-label">3. {{ translation.calculation[selectLanguage] }}</div>
        <div class="calculation">
          <div class="item">
            <div class="pay-for-check-product">
              <div class="field-label">{{translation.view.pay_to_check_product_to_usd[selectLanguage]}}</div>
              <div class="field-value">${{(documentById.pay_to_check_product_to_usd).toFixed(2)}}</div>
            </div>
            <div class="document-item">
              <div class="field-label">{{ translation.view.item_to_usd[selectLanguage] }} из {{itemTypes[documentById.item_type]}}</div>
              <div class="field-value">${{(documentById.item_to_usd).toFixed(2)}}</div>
            </div>
            <div class="delivery-to-storage">
              <div class="field-label">{{ translation.view.total_for_delivery_to_storage[selectLanguage] }}</div>
              <div class="field-value">${{(documentById.total_for_delivery_to_storage).toFixed(2)}}</div>
            </div>
            <div class="deliver-percentage">
              <div class="field-label">{{ translation.view.deliver_price_percentage[selectLanguage] }} {{documentById.deliver_price_percentage}}%</div>
              <div class="field-value">${{((documentById.delivery_price * documentById.deliver_price_percentage) / 100).toFixed(2)}}</div>
            </div>
            <div class="unload-reload">
              <div class="field-label">{{ translation.view.unload_reload[selectLanguage] }}</div>
              <div class="field-value">${{(documentById.total_for_reload_unload).toFixed(2)}}</div>
            </div>
            <div class="different-payment">
              <div class="field-label">{{ translation.view.different_payment[selectLanguage] }}</div>
              <div class="field-value">${{documentById.different_payment}}</div>
            </div>
            <div class="pay-to">
              <div class="field-label">{{ translation.pay_to_date[selectLanguage] }}</div>
              <div class="field-value">{{documentById.pay_to_date}}</div>
            </div>
            <div class="pay-to-yuan">
              <div class="field-label">{{ translation.pay_by_yuan[selectLanguage] }}</div>
              <div class="field-value">{{documentById.pay_by_yuan}}</div>
            </div>
          </div>
          <div class="total-price">
            <div class="description_expenses">
              <div class="field-label">{{ translation.description_expenses[selectLanguage] }}</div>
              <div class="field-value">{{documentById.description_expenses}}</div>
            </div>
            <div class="delivery-price">
              <div class="field-label">{{ translation.view.delivery_price[selectLanguage] }}</div>
              <div class="field-value">$ {{documentById.delivery_price}}</div>
            </div>
            <div class="info">
              <span class="total-price-label">{{ translation.view.total_price[selectLanguage] }}: </span>
              <span class="field-value" style="color: #005EB8">${{ Math.round(documentById.total_price) }}</span>
            </div>
          </div>
        </div>
        <hr/>
        <div class="others">
          <div class="description">
            <span class="field-label">{{ translation.description[selectLanguage] }}</span>
            <div class="description-value">{{documentById.description}}</div>
          </div>
          <div class="whatsapp-phone-number" style="padding: 5px 0">
            <span class="field-label">{{ translation.whatsapp_phone_number[selectLanguage] }}</span>
            <div class="whatsapp-value">{{documentById.whatsapp_phone_number}}</div>
          </div>
        </div>
      </div>
      <div class="final" style="background: #0173D4; color: #fff; padding: 5px 3px">
        <div class="summary">
          {{documentById.summary}}
        </div>
      </div>
    </div>
    <div v-if="getCargoTrackById" class="card" style="width: 400px;margin-left: 30px; justify-content: flex-start">
      <div class="cargo-storage">
        <label>Груз на складе</label>
        <button class="btn" v-bind:disabled="getCargoTrackById.storage.isDone">{{getCargoTrackById.storage.isDone ? 'Выбрано' : 'Выбрать'}}</button>
      </div>
      <div class="cargo-storage">
        <label>Груз отправлен</label>
        <button
            class="btn"
            v-bind:disabled="getCargoTrackById.sent.isDone"
            v-on:click="setCargoTrack($event, 'sent')"
        >{{getCargoTrackById.sent.isDone ? 'Выбрано' : 'Выбрать'}}</button>
      </div>
      <div class="cargo-storage">
        <label>Груз на таможне</label>
        <button
            class="btn"
            v-bind:disabled="getCargoTrackById.customs.isDone"
            v-on:click="setCargoTrack($event, 'customs')"
        >{{getCargoTrackById.customs.isDone ? 'Выбрано' : 'Выбрать'}}</button>
      </div>
      <div class="cargo-custom-storage">
        <label>Груз прибыл в пункт растаможке товаров</label>
        <div class="form-control">
          <input v-bind:disabled="getCargoTrackById.customClearancePoint.isDone" placeholder="Москва, Хабаровск, или другой город" v-model="custom_clearance_place" type="text">
        </div>
        <button
            class="btn"
            v-bind:disabled="getCargoTrackById.customClearancePoint.isDone"
            v-on:click="setCargoTrack($event, 'custom_clearance_place')"
        >{{getCargoTrackById.customClearancePoint.isDone ? 'Выбрано' : 'Выбрать'}}</button>
      </div>
      <div class="cargo-storage">
        <label>Груз прибыл</label>
        <button
            class="btn"
            v-bind:disabled="getCargoTrackById.arrival.isDone"
            v-on:click="setCargoTrack($event, 'arrival')"
        >{{getCargoTrackById.arrival.isDone ? 'Выбрано' : 'Выбрать'}}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import * as htmlToImage from 'html-to-image';
import {Translation} from '@/common/translation';
import {setCargoTrack} from "@/store/Document/actions";
import {changeClientManagerPhone, changePresenterPhoneNumber} from "@/store/Auth/actions";
import Logo from '@/assets/logo.png';
import {ItemTypes} from "@/common/freight_types";

export default {
  name: "ViewDocument",
  mounted() {
    const {dispatch} = this.$store;
    dispatch('getDocumentById', this.documentId);
    dispatch('getCargoTrackById', this.documentId);
    dispatch('getPhones');
  },
  methods: {
    onBlurClientManager(event) {
      changeClientManagerPhone(this.getPhonesNumber.id, event.target.innerText);
    },
    onBlurPresenter(event) {
      changePresenterPhoneNumber(this.getPhonesNumber.id, event.target.innerText);
    },
    onMoveBack(event) {
      event.preventDefault();
      this.$router.push('/documents');
    },
    onDownloadImage(event) {
      event.preventDefault();
      const element = document.getElementById('card-id');
      htmlToImage.toPng(element).then(url => {
        const aElement = document.createElement('a');
        aElement.href = url;
        aElement.download = `${this.documentById.label}.png`;
        aElement.click();
      })
    },
    onEditDocument(event) {
      event.preventDefault();
      this.$router.push(`/documents/${this.documentId}/edit`);
    },
    setCargoTrack(event, key) {
      const {dispatch} = this.$store;
      setCargoTrack(this.documentId, key, this.custom_clearance_place).then(() => {
        dispatch('getCargoTrackById', this.documentId);
      });
    }
  },
  computed: {
    documentById() {
      const {state} = this.$store;
      return state.documentModule.documentById ? {
        ...state.documentModule.documentById,
        departure_date: moment(state.documentModule.documentById.departure_date).format('DD.MM.YYYY'),
        arrival_date: moment(state.documentModule.documentById.arrival_date).format('DD.MM.YYYY'),
        pay_to_date: moment(state.documentModule.documentById.pay_to_date).format('DD.MM.YYYY')
      } : state.documentModule.documentById;
    },
    getCargoTrackById() {
      const {state} = this.$store;
      return state.documentModule.cargoPlace
    },
    getPhonesNumber() {
      const {state} = this.$store;
      return state.documentModule.phones
    }
  },
  data() {
    const documentId = Number(this.$route.params.id);
    return {
      logo: Logo,
      itemTypes: ItemTypes,
      selectLanguage: 'RUS',
      custom_clearance_place: '',
      translation: Translation,
      documentId
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/theme";
.container {
  height: 100%;
  width: 1200px;
  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  .custom-card {
    width: 1050px !important;
    padding: 2rem 2rem 0 2rem;
    margin-bottom: 0;
    border-radius: 0;
  }
  .card {
    width: 100%;
    .field-label {
      font-size: 12px;
      font-weight: 500;
      padding-right: 5px;
    }
    .field-value {
      font-size: 12px;
      font-weight: 400;
    }
    .header {
      display: flex;
      justify-content: space-between;
      .left-side {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .logo {
          img {
            width: 300px;
          }
        }
        .document-label {
          font-size: 20px;
          display: flex;
          span {
            font-weight: 600;
            padding-right: 5px;
          }
        }
      }
      .right-side {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .top-info {
          font-size: 13px;
          font-weight: 500;
          padding: 2px 0;
        }
        .company-title {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    .receiver-label {
      text-align: start;
      font-weight: 500;
      font-size: 22px;
    }
    .receiver {
      display: flex;
      justify-content: space-between;
      .left-side {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        .full-name {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
      .right-side {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
        .delivery-point {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
    .product-label {
      display: flex;
      justify-content: flex-start;
      font-size: 22px;
      font-weight: 500;
    }
    .product {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .header-row {
        display: flex;
        justify-content: space-between;
        .product-name-header-cell {
          display: flex;
          font-size: 12px;
          font-weight: 500;
          width: 600px;
          padding: 3px;
        }
        .product-type-header-cell {
          display: flex;
          font-size: 12px;
          font-weight: 500;
          width: 300px;
          padding: 3px;
        }
        .header-cell {
          display: flex;
          font-size: 12px;
          font-weight: 500;
          width: 30%;
          padding: 3px;
        }
      }
      .body-row {
        display: flex;
        justify-content: space-between;
        .product-name-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 600px;
          background: $background;
          padding: 5px;
          margin: 0 3px;
        }
        .product-type-cell {
          width: 300px;
          background: $background;
          padding: 5px;
          margin: 0 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .body-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30%;
          background: $background;
          padding: 5px;
          margin: 0 3px;
        }
      }
    }
    .calculation {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .item {
        display: flex;
        align-items: flex-end;
        .field-label {
          padding: 3px 0;
          padding-right: 5px;
          text-align: start;
          margin-left: 5px;
        }
        .field-value {
          background: $background;
          padding: 5px;
          margin: 0 3px;
          min-height: 25px;
        }
      }
      .total-price {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-weight: 500;
        font-size: 16px;
        .field-value {
          font-size: 16px;
          font-weight: 500;
        }
        .total-price-label {
          color: $logo-color;
        }
        .description_expenses {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .field-value {
            text-align: start;
            width: 300px;
            flex-wrap: wrap;
            color: $description-text;
            font-size: 12px;
          }
        }
        .delivery-price {
          display: flex;
          align-items: center;
          .field-value {
            color: $description-text;
            font-size: 12px;
          }
        }
      }
    }
    .others {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        .description-value {
          font-size: 11px;
          text-align: start;
          word-break: break-word;
          color: $description-text;
        }
      }
      .whatsapp-phone-number {
        display: flex;
        align-items: center;
      }
    }
  }
  .cargo-storage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
  }
  .cargo-custom-storage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    label {
      font-size: 14px !important;
    }
  }
}
</style>