import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = {
  key: 1,
  class: "header-table"
}
const _hoisted_3 = { class: "header-bar" }
const _hoisted_4 = { class: "logout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoggedIn === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["list-documents bar", _ctx.active === '/documents' ? 'active' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onMove('/documents')))
            }, " Накладные ", 2),
            (_ctx.userInfo && _ctx.userInfo.role === 'ADMIN')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["list-clients bar", _ctx.active === '/clients' ? 'active' : '']),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMove('/clients')))
                }, " База Клиента ", 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["calculation bar", _ctx.active === '/calculation' ? 'active' : '']),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMove('/calculation')))
            }, " Вычисление ", 2)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onLogOut($event)))
            }, "Выйти")
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn !== null)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}