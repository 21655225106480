import { FilterRequest } from '@/interfaces/common';
import Axios from 'axios';
import Paths from '@/paths/index';

export const createDensity = (data: Array<{score: number, type: string, price: number}>) => {
  return Axios.post(Paths.calculation.density('manage'), {data}, {
    withCredentials: true
  });
}

export const CalculationActions = {
  getDensity(context: any) {
    Axios.post(Paths.calculation.density('get'), undefined, {
      withCredentials: true
    }).then(res => {
      console.log(res);
      context.commit('setDensity', res.data.list);
    })
  },
  createDensity(context: any, data: Array<{score: number, type: string, price: number}>) {
    Axios.post(Paths.calculation.density('manage'), {data}, {
      withCredentials: true
    }).then(res => {
      context.commit('setDensity', res.data);
    });
  },
  getPayByFreight(context: any) {
    Axios.post(Paths.calculation.payByFreight('get'), undefined, {
      withCredentials: true
    }).then(res => {
      context.commit('setPayByFreight', res.data.list);
    })
  },
  createPayByFreight(context: any, data: Array<{days: number, type: string}>) {
    Axios.post(Paths.calculation.payByFreight('manage'), {data}, {
      withCredentials: true
    }).then((res: any) => {
      context.commit('setPayByFreight', res.data);
    })
  },
  getDeliveryByFreight(context: any) {
    Axios.post(Paths.calculation.deliveryByFreight('get'), undefined, {
      withCredentials: true
    }).then((res: any) => {
      context.commit('setDeliveryByFreight', res.data.list);
    })
  },
  createDeliveryByFreight(context: any, data: Array<{days: number, type: string}>) {
    Axios.post(Paths.calculation.deliveryByFreight('manage'), {data}, {
      withCredentials: true
    }).then((res: any) => {
      context.commit('setDeliveryByFreight', res.data);
    })
  }
};
