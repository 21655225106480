<template>
  <div class="card calculation">
    <div class="freight_types" style="display: flex; justify-content: space-between;">
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.express ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.express)"
      >{{freight_types.express}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.fly ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.fly)"
      >{{freight_types.fly}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.slowFly ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.slowFly)"
      >{{freight_types.slowFly}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.train ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.train)"
      >{{freight_types.train}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.oneWayTrain ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.oneWayTrain)"
      >{{freight_types.oneWayTrain}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.trainWithWear ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.trainWithWear)"
      >{{freight_types.trainWithWear}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.fastAuto ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.fastAuto)"
      >{{freight_types.fastAuto}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.fastWhiteAuto ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.fastWhiteAuto)"
      >{{freight_types.fastWhiteAuto}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.fastWearAuto ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.fastWearAuto)"
      >{{freight_types.fastWearAuto}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.slowWearAuto ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.slowWearAuto)"
      >{{freight_types.slowWearAuto}}</div>
      <div
          class="freight_type"
          :class="selected_freight_type === freight_types.slowAuto ? 'active_freight_type' : ''"
          v-on:click="onSelectFreightType(freight_types.slowAuto)"
      >{{freight_types.slowAuto}}</div>
    </div>
    <div class="list_data">
      <div class="density">
        <div class="header">
          <label>Данные о плотности</label>
        </div>
        <div class="body">
          <div class="item" v-for="(density, idx) of densityForm[selected_freight_type]" v-bind:key="idx">
            <div class="score form-control">
              <label for="density.from">От</label>
              <input id="density.from" type="number" :value="density.from" v-on:input="onChangeDensity($event, idx, 'from')"/>
            </div>
            <div class="score form-control">
              <label for="density.to">До</label>
              <input id="density.to" type="number" :value="density.to" v-on:input="onChangeDensity($event, idx, 'to')"/>
            </div>
            <div class="price form-control">
              <label for="density.price">Цена за {{density.type}}</label>
              <input step="0.01" id="density.price" type="number"  :value="density.price" v-on:input="onChangeDensity($event, idx, 'price')"/>
            </div>
            <div class="type form-control">
              <label>Тип</label>
              <select v-model="density.type">
                <option value="KG">Кг</option>
                <option value="VOLUME">Обьем</option>
              </select>
            </div>
          </div>
          <div class="action">
            <button class="btn" v-on:click="onAddNewField($event, 'createDensity')">Добавить новое</button>
            <button class="btn" v-on:click="onSaveDensity($event)">Сохранить</button>
          </div>
        </div>
      </div>
      <div class="payByFreight">
        <div class="header">
          <label>Данные о оплаты</label>
        </div>
        <div class="body">
          <div class="item" v-for="(item, idx) of payByFreightForm[selected_freight_type]" v-bind:key="idx">
            <div class="days form-control">
              <label for="item.days">Дней</label>
              <input id="item.days" type="number" :value="item.days" v-on:input="onChangePayByFreight($event, idx, 'days')"/>
            </div>
          </div>
          <div class="action">
            <button class="btn" v-on:click="onAddNewField($event, 'createPayByFreight')">Добавить новое</button>
            <button class="btn" v-on:click="onSavePayByFreight($event)">Сохранить</button>
          </div>
        </div>
      </div>
      <div class="deliveryByFreight">
        <div class="header">
          <label>Данные о днях доставки</label>
        </div>
        <div class="body">
          <div class="item" v-for="(item, idx) of deliveryByFreightForm[selected_freight_type]" v-bind:key="idx">
            <div class="days form-control">
              <label for="item.days">Дней</label>
              <input type="number" :value="item.days" v-on:input="onChangeDeliveryByFreight($event, idx, 'days')"/>
            </div>
          </div>
          <div class="action">
            <button class="btn" v-on:click="onAddNewField($event, 'createDeliveryByFreight')">Добавить новое</button>
            <button class="btn" v-on:click="onSaveDeliveryByFreight($event)">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Options, Vue } from 'vue-class-component';
import Modal from '@/components/Modal.vue';
import {Freight_types} from "@/common/freight_types";

@Options({
  name: 'Calculation',
  components: {
    Modal
  },
  data() {
    return {
      selected_freight_type: Freight_types.express,
      freight_types: Freight_types,
      selectedDensityType: '',
      densityForm: {},
      payByFreightForm: {},
      deliveryByFreightForm: {},
      isOpenDensity: false,
      isOpenPay: false,
      isOpenDelivery: false
    }
  },
  mounted() {
    const {dispatch} = this.$store;
    dispatch('getDensity');
    dispatch('getPayByFreight');
    dispatch('getDeliveryByFreight');
  },
  methods: {
    onSelectFreightType(type) {
      this.selected_freight_type = type;
    },
    onAddNewField(event, type) {
      event.preventDefault();
      const {dispatch} = this.$store;
      const actions = {
        createDensity: () => {
          const newForm = []
          Object.keys(this.densityForm).forEach((key) => {
            Object.keys(this.densityForm[key]).forEach((idx) => {
              console.log(this.densityForm[key][idx]);
              newForm.push(this.densityForm[key][idx]);
            })
          })
          newForm.push({
            from: 0,
            to: 0,
            price: 0,
            type: 'KG',
            freight_type: this.selected_freight_type
          });
          return newForm;
        },
        createPayByFreight: () => {
          const newForm = [];
          Object.keys(this.payByFreightForm).forEach((key) => {
            Object.keys(this.payByFreightForm[key]).forEach((idx) => {
              console.log(this.payByFreightForm[key][idx]);
              newForm.push(this.payByFreightForm[key][idx]);
            })
          })
          newForm.push({
            days: 0,
            freight_type: this.selected_freight_type
          });
          return newForm;
        },
        createDeliveryByFreight: () => {
          const newForm = [];
          Object.keys(this.deliveryByFreightForm).forEach((key) => {
            Object.keys(this.deliveryByFreightForm[key]).forEach((idx) => {
              console.log(this.deliveryByFreightForm[key][idx]);
              newForm.push(this.deliveryByFreightForm[key][idx]);
            })
          })
          newForm.push({
            days: 0,
            freight_type: this.selected_freight_type
          });
          return newForm;
        }
      }
      const finalForm = actions[type]();
      dispatch(type, finalForm)
    },
    onSaveDensity(event) {
      event.preventDefault();
      const {dispatch} = this.$store;
      const newForm = []
      Object.keys(this.densityForm).forEach((key) => {
        Object.keys(this.densityForm[key]).forEach((idx) => {
          console.log(this.densityForm[key][idx]);
          newForm.push(this.densityForm[key][idx]);
        })
      })
      dispatch('createDensity', newForm)
    },
    onSavePayByFreight(event) {
      event.preventDefault();
      const {dispatch} = this.$store;
      const newForm = []
      Object.keys(this.payByFreightForm).forEach((key) => {
        Object.keys(this.payByFreightForm[key]).forEach((idx) => {
          console.log(this.payByFreightForm[key][idx]);
          newForm.push(this.payByFreightForm[key][idx]);
        })
      })
      dispatch('createPayByFreight', newForm)
    },
    onSaveDeliveryByFreight(event) {
      event.preventDefault();
      const {dispatch} = this.$store;
      const newForm = []
      Object.keys(this.deliveryByFreightForm).forEach((key) => {
        Object.keys(this.deliveryByFreightForm[key]).forEach((idx) => {
          console.log(this.deliveryByFreightForm[key][idx]);
          newForm.push(this.deliveryByFreightForm[key][idx]);
        })
      })
      dispatch('createDeliveryByFreight', newForm)
    },
    onChangeDensity(event, id, key) {
      this.densityForm[this.selected_freight_type][id] = {
        ...this.densityForm[this.selected_freight_type][id],
        [key]: Number(event.target.value)
      };
    },
    onChangePayByFreight(event, id, key) {
      this.payByFreightForm[this.selected_freight_type][id] = {
        ...this.payByFreightForm[this.selected_freight_type][id],
        [key]: event.target.value
      };
    },
    onChangeDeliveryByFreight(event, id, key) {
      this.deliveryByFreightForm[this.selected_freight_type][id] = {
        ...this.deliveryByFreightForm[this.selected_freight_type][id],
        [key]: event.target.value
      };
    }
  },
  computed: {
    density() {
      const {state} = this.$store;
      return state.commonModule.density
    },
    payByFreight() {
      const {state} = this.$store;
      return state.commonModule.payByFreight
    },
    deliveryByFreight() {
      const {state} = this.$store;
      return state.commonModule.deliveryByFreight
    }
  },
  watch: {
    density(value) {
      this.densityForm = {};
      value.forEach(item => {
        const newItem = {
          from: item.from,
          to: item.to,
          price: item.price,
          type: item.type,
          freight_type: item.freight_type
        };
        this.densityForm[item.freight_type] = this.densityForm[item.freight_type] ? [...this.densityForm[item.freight_type], newItem] : [newItem];
      });
    },
    payByFreight(value) {
      this.payByFreightForm = {};
      value.forEach(item => {
        const newItem = {
          days: item.days,
          freight_type: item.freight_type
        };
        this.payByFreightForm[item.freight_type] = this.payByFreightForm[item.freight_type] ? [...this.payByFreightForm[item.freight_type], newItem] : [newItem];
      })
    },
    deliveryByFreight(value) {
      this.deliveryByFreightForm = {};
      value.forEach(item => {
        const newItem = {
          days: item.days,
          freight_type: item.freight_type
        };
        this.deliveryByFreightForm[item.freight_type] = this.deliveryByFreightForm[item.freight_type] ? [...this.deliveryByFreightForm[item.freight_type], newItem] : [newItem];
      });
    }
  }
})
export default class Calculation extends Vue {
}
</script>

<style lang="scss">
@import "src/assets/scss/theme";

.calculation {
  //display: flex;
  //justify-content: center;
  overflow-y: auto;
  height: auto;
  .freight_types {
    .freight_type {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3eaf7c;
      padding: 5px 10px;
      border-radius: 6px;
      margin: 0 5px;
      background: #fff;
      height: 40px;
      &:hover {
        background: #3eaf7c;
        color: #fff;
        cursor: pointer;
      }
    }
    .active_freight_type {
      background: #3eaf7c;
      color: #fff;
    }
  }
  .list_data {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    .header {
      display: flex;
      align-items: center;
      border: 1px solid $border-color;
      padding: 5px;
      border-radius: 6px;
    }
    .body {
      display: flex;
      flex-direction: column;
      border: 1px solid $border-color;
      border-radius: 6px;
      border-top: none;
      .item {
        display: flex;
        justify-content: space-evenly;
        margin: 5px 0;
        align-items: center;
        .score {
          padding: 0 5px;
        }
        .type {
          padding: 0 5px;
        }
        .days {
          padding: 0 5px;
        }
      }
      .action {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-right: 5px;
        padding-left: 10px;
      }
    }
    .density {
      margin-right: 10px;
    }
    .payByFreight {
      margin: 0 10px;
    }
    .deliveryByFreight {
      margin-left: 10px;
    }
  }
}
</style>