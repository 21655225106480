<template>
  <div class="card">
    <div class="search form-control">
      <input placeholder="Поиск..." v-on:input="onSearch($event)"/>
    </div>
    <Pagination :page="pageRequest.page" :totalPages="totalPages" @move="onMove"/>
    <ul class="list">
      <div class="headers">
        <div class="header">ФИО</div>
        <div class="header">Код Клиента</div>
        <div class="header">Паспорт</div>
        <div class="header">Телефонный номер</div>
        <div class="header">Вид фрахта</div>
      </div>
      <li
          class="list-item"
          v-for="(client, idx) in listClients.list"
          v-bind:key="idx"
          v-on:click="onViewItem(client)"
      >
        <span class="body-item">{{client.full_name}}</span>
        <span class="body-item">{{client.code}}</span>
        <span class="body-item">{{client.passport}}</span>
        <span class="body-item">{{client.phone_number}}</span>
        <span class="body-item">{{client.freight_type}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Pagination from '@/components/Pagination.vue';

@Options({
  name: "ListClients",
  mounted() {
    const {dispatch} = this.$store;
    dispatch('getListClients', {pageRequest: this.pageRequest, searchString: this.searchString})
  },
  data() {
    return {
      searchString: null,
      pageRequest: {
        limit: 20,
        page: 1
      }
    }
  },
  methods: {
    onViewItem(client) {
      this.$router.push(`/clients/${client.id}`);
    },
    onSearch(event) {
      const {dispatch} = this.$store;
      const {value} = event.target;
      this.searchString = value;
      if (this.searchString !== null && (this.searchString.length >= 3 || this.searchString.length === 0)) {
        dispatch('getListClients', {pageRequest: this.pageRequest, searchString: this.searchString})
      }
    },
    onMove(page) {
      this.pageRequest.page = page;
      const {dispatch} = this.$store;
      dispatch('getListClients', {pageRequest: this.pageRequest, searchString: this.searchString})
    }
  },
  components: {
    Pagination
  },
  computed: {
    listClients() {
      const {state} = this.$store;
      return state.clientModule.listClients
    },
    totalPages() {
      return Math.ceil(this.listClients.totalCount / this.pageRequest.limit);
    }
  }
})
export default class ListClients extends Vue {}
</script>

<style lang="scss">
@import "src/assets/scss/theme";
.card {
  height: 100vh;
}
.createDocument {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.search {
  input {
    border-radius: 6px;
  }
}
.headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $border-color;
  padding: 5px;
  border-radius: 4px;
  .header {
    display: flex;
    justify-self: start;
    width: 33.3%;
    font-weight: 600;
  }
}
.list-item {
  display: flex;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: $border-color;
  padding: 5px;
  &:hover {
    color: #fff;
    background: #2c3e50;
    cursor: pointer;
  }
  .body-item {
    display: flex;
    justify-self: start;
    width: 33.3%;
    padding-left: 3px;
  }
}
</style>