<template>
  <div class="pagination">
    <div class="prev" :class="page <= 1 ? 'disabled' : 'active'" v-on:click="onMove(page - 1)">Предыдущий</div>
    <span>:</span>
    <div class="next" :class="page >= totalPages ? 'disabled' : 'active'" v-on:click="onMove(page + 1)">Следующий</div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    page: Number,
    totalPages: Number
  },
  methods: {
    onMove(page) {
      this.$emit('move', page);
    }
  }
})
export default class Pagination extends Vue {
}
</script>

<style lang="scss">

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  .prev {
    margin-right: 5px;
  }
  .next {
    margin-left: 5px;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .active {
    cursor: pointer;
  }
}
</style>