import {AuthState, AuthStoreInterface} from './state';
import {AuthActions} from './actions';
import {StoreOptions} from 'vuex';

export const AuthModule = {
  state: () => AuthState,
  actions: AuthActions,
  getters: {
    isLoggedIn: (state: AuthStoreInterface) => state.isLoggedIn
  },
  mutations: {
    setLoggedIn(state: AuthStoreInterface, isLoggedIn: boolean) {
      state.isLoggedIn = isLoggedIn;
    },
    setCurrentUser(state: AuthStoreInterface, payload: any) {
      console.log('set current');
      console.log(payload);
      state.userInfo = payload
    }
  }
}
